import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import get from 'lodash/get';

import Link from '../../link';
import { useStaticProps } from '../../../components/context/static-props';
import { trackEvent } from '../../../lib/melidata';

const namespace = 'ui-search-header--official-store';

const OfficialStoreHeader = ({ className = null, logo, title, background, url }) => {
  const { lowEnd } = useStaticProps();

  return (
    <div className={classnames(className, namespace)}>
      <Image
        src={get(background, 'pictures.2x')}
        preload
        lazyload="off"
        className={`${namespace}__banner`}
        lowEnd={lowEnd}
        alt="official store"
      />
      <div className={`${namespace}__wrapper`}>
        <Link
          className={`${namespace}__link`}
          href={url}
          title={title}
          tabIndex="-1"
          isInternal={false}
          onClick={() =>
            trackEvent({ path: '/search/official_store_logo/click', event_data: { store: title, url }, clean: true })
          }
        >
          <Image
            alt={title}
            className={`${namespace}__image`}
            src={get(logo, 'pictures.2x')}
            lowEnd={lowEnd}
            preload
            lazyload="off"
          />
        </Link>
      </div>
    </div>
  );
};

OfficialStoreHeader.propTypes = {
  background: shape({
    pictures: shape({
      '2x': string.isRequired,
    }).isRequired,
  }).isRequired,
  className: string,
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }).isRequired,
  }).isRequired,
  title: string.isRequired,
  url: string.isRequired,
};

export default OfficialStoreHeader;
